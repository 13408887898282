import type {File, Image} from '~/types/sofie'
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Email: string;
  FacetDistribution: any;
  Iban: string;
  Image: Image;
  JSON: any;
  Mixed: any;
  Null: null;
  PrivateFile: any;
  PublicFile: any;
  Upload: any;
  Uuid: string;
};

export type Address = {
  __typename?: 'Address';
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  geodata?: Maybe<Scalars['JSON']>;
  house_number?: Maybe<Scalars['String']>;
  house_number_suffix?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  house_number?: InputMaybe<Scalars['String']>;
  house_number_suffix?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CampaignRequest = {
  __typename?: 'CampaignRequest';
  capabilities: Array<Capability>;
  company?: Maybe<Scalars['String']>;
  company_logo: Array<Scalars['Image']>;
  contact?: Maybe<Scalars['String']>;
  countries?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  flower?: Maybe<Flower>;
  id: Scalars['Int'];
  image: Array<Scalars['Image']>;
  phone?: Maybe<Scalars['String']>;
  product_info?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  video: Array<Scalars['PrivateFile']>;
};

export type Capability = {
  __typename?: 'Capability';
  name: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  active_image?: Maybe<Scalars['Image']>;
  ancestors: Array<Category>;
  capabilities: Array<Capability>;
  children: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']>;
  depth?: Maybe<Scalars['Int']>;
  descendants: Array<Category>;
  id: Scalars['Int'];
  images: Array<Scalars['Image']>;
  name: Scalars['String'];
  parent?: Maybe<Category>;
  parent_id?: Maybe<Scalars['Int']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Collection = {
  __typename?: 'Collection';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  entries: Array<CollectionEntry>;
  entries_count?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Image']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  session_id?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  title: Scalars['String'];
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  uuid: Scalars['Uuid'];
};

export type CollectionEntry = {
  __typename?: 'CollectionEntry';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  flower: Flower;
  flower_cultivar?: Maybe<FlowerCultivar>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['Uuid'];
};

export type CollectionOrderResult = {
  __typename?: 'CollectionOrderResult';
  collection: Collection;
  supplier: Supplier;
  url: Scalars['String'];
};

export enum CollectionTypeEnum {
  Favorites = 'favorites',
  Order = 'order'
}

export type Event = {
  __typename?: 'Event';
  activecampaign_list_id: Scalars['Int'];
  address?: Maybe<Address>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
  time_end?: Maybe<Scalars['String']>;
  time_start?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['Uuid'];
};

export enum FaqTypeEnum {
  Campaign = 'Campaign'
}

export type Flower = {
  __typename?: 'Flower';
  about_colors?: Maybe<Scalars['String']>;
  about_style: Scalars['String'];
  about_symbolism: Scalars['String'];
  /** Leeg wordt de standaard tekst getoond */
  advice_care_tips?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  advice_meaning?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  advice_presentation?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  advice_sustainability?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  advice_versatility?: Maybe<Scalars['String']>;
  availability: Scalars['Mixed'];
  capabilities: Array<Capability>;
  /** Leeg wordt de standaard tekst getoond */
  care_cut?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  care_nutrition?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  care_prune?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  care_sun?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  care_vase?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  care_water?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  /** Productcodes van de cultivars die geïmporteerd moeten worden, één per regel */
  code_cultivars?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  florists_count?: Maybe<Scalars['Int']>;
  image_primary?: Maybe<Scalars['Image']>;
  images_additional: Array<Scalars['Image']>;
  inspiration_intro?: Maybe<Scalars['String']>;
  intro: Scalars['String'];
  origin: Scalars['String'];
  origin_countries_count?: Maybe<Scalars['Int']>;
  origin_top_countries: Array<Scalars['String']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  seo?: Maybe<Seo>;
  slug: Scalars['String'];
  /** Leeg wordt de standaard tekst getoond */
  techniques_arrangement?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  techniques_bouquets?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  techniques_drying?: Maybe<Scalars['String']>;
  /** Leeg wordt de standaard tekst getoond */
  techniques_mono?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  title_latin: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  uuid: Scalars['Uuid'];
};

export type FlowerAlphabetGroup = {
  __typename?: 'FlowerAlphabetGroup';
  flowers: Array<Flower>;
  letter: Scalars['String'];
};

export type FlowerCultivar = {
  __typename?: 'FlowerCultivar';
  active_image?: Maybe<Scalars['Image']>;
  capabilities: Array<Capability>;
  color?: Maybe<Scalars['String']>;
  color_hex?: Maybe<Scalars['String']>;
  color_rhs?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  floricode_product_id?: Maybe<Scalars['String']>;
  flower: Flower;
  images: Array<Scalars['Image']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  registered_at: Scalars['Date'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['Uuid'];
};

export enum FlowerCultivarColorEnum {
  Black = 'black',
  Blue = 'blue',
  Brown = 'brown',
  Gray = 'gray',
  Green = 'green',
  Mixed = 'mixed',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  White = 'white',
  Yellow = 'yellow'
}

export type FlowerCultivarPagination = {
  __typename?: 'FlowerCultivarPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data: Array<FlowerCultivar>;
  /** Facet distribution */
  facet_distribution?: Maybe<Scalars['FacetDistribution']>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export type FlowerDay = {
  __typename?: 'FlowerDay';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  date: Scalars['Date'];
  flower: Flower;
  image_primary_override?: Maybe<Scalars['Image']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['Uuid'];
};

export type FlowerDayResult = {
  __typename?: 'FlowerDayResult';
  date: Scalars['Date'];
  flower: Flower;
  image_primary_override?: Maybe<Scalars['Image']>;
  next?: Maybe<FlowerDay>;
  previous?: Maybe<FlowerDay>;
};

export type Form = {
  __typename?: 'Form';
  fields: Array<FormField>;
  id: Scalars['Int'];
  schema: Scalars['JSON'];
  schema_v2: Scalars['Mixed'];
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  uuid: Scalars['Uuid'];
};

export type FormData = {
  __typename?: 'FormData';
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
};

export type FormField = {
  __typename?: 'FormField';
  fields: Array<FormField>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  regex?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  unique?: Maybe<Scalars['Boolean']>;
};

export type FormFieldAttachment = {
  attachment: Scalars['Upload'];
  field_name: Scalars['String'];
};

export type FrequentlyAskedQuestion = {
  __typename?: 'FrequentlyAskedQuestion';
  answer: Scalars['String'];
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum LocaleEnum {
  Da = 'da',
  De = 'de',
  En = 'en',
  Es = 'es',
  Fi = 'fi',
  Fr = 'fr',
  It = 'it',
  Nl = 'nl',
  Pl = 'pl',
  Sv = 'sv'
}

export type Menu = {
  __typename?: 'Menu';
  items?: Maybe<Array<Maybe<MenuItem>>>;
  name?: Maybe<Scalars['String']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  children?: Maybe<Array<Maybe<MenuItem>>>;
  scope?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<Scalars['Boolean']>;
  addToCollection: Array<CollectionEntry>;
  changePassword?: Maybe<Scalars['Null']>;
  consolidateUserFavoritesOnAuthenticated: Scalars['Boolean'];
  createCollection: Collection;
  disableTwoFactor?: Maybe<Scalars['Boolean']>;
  enableTwoFactor?: Maybe<Scalars['Boolean']>;
  eventSignUp?: Maybe<Scalars['Boolean']>;
  generateTwoFactorSecret?: Maybe<TwoFactorSecret>;
  login?: Maybe<Scalars['String']>;
  logout?: Maybe<Scalars['Boolean']>;
  mailCollection?: Maybe<Scalars['Null']>;
  orderCollection: CollectionOrderResult;
  register?: Maybe<Scalars['Null']>;
  removeCollection?: Maybe<Scalars['Null']>;
  removeFromCollection?: Maybe<Scalars['Null']>;
  requestPasswordReset?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  submitCampaignRequest: CampaignRequest;
  submitForm?: Maybe<Scalars['Null']>;
  updateCollection: Collection;
  updatePersonalDetails?: Maybe<Scalars['Null']>;
  updatePreferences?: Maybe<Scalars['Null']>;
};


export type MutationAcceptInviteArgs = {
  email: Scalars['Email'];
  invite: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAddToCollectionArgs = {
  flower_cultivar_ids?: InputMaybe<Array<Scalars['Uuid']>>;
  flower_ids?: InputMaybe<Array<Scalars['Uuid']>>;
  id?: InputMaybe<Scalars['Uuid']>;
};


export type MutationChangePasswordArgs = {
  current_password: Scalars['String'];
  new_password: Scalars['String'];
  new_password_confirm: Scalars['String'];
};


export type MutationCreateCollectionArgs = {
  title: Scalars['String'];
};


export type MutationEnableTwoFactorArgs = {
  otp: Scalars['String'];
};


export type MutationEventSignUpArgs = {
  email: Scalars['Email'];
  event: Scalars['Uuid'];
};


export type MutationLoginArgs = {
  email: Scalars['Email'];
  password: Scalars['String'];
  two_factor_code?: InputMaybe<Scalars['String']>;
};


export type MutationMailCollectionArgs = {
  email: Scalars['Email'];
  id?: InputMaybe<Scalars['Uuid']>;
};


export type MutationOrderCollectionArgs = {
  id?: InputMaybe<Scalars['Uuid']>;
  supplier_id: Scalars['Uuid'];
};


export type MutationRegisterArgs = {
  address?: InputMaybe<AddressInput>;
  captcha: Scalars['String'];
  email: Scalars['Email'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  locale: Scalars['String'];
  magazine_digital?: InputMaybe<Scalars['Boolean']>;
  magazine_print?: InputMaybe<Scalars['Boolean']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  specialty: UserSpecialtyEnum;
};


export type MutationRemoveCollectionArgs = {
  id: Scalars['Uuid'];
};


export type MutationRemoveFromCollectionArgs = {
  flower_cultivar_ids?: InputMaybe<Array<Scalars['Uuid']>>;
  flower_ids?: InputMaybe<Array<Scalars['Uuid']>>;
  id?: InputMaybe<Scalars['Uuid']>;
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['Email'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSubmitCampaignRequestArgs = {
  captcha: Scalars['String'];
  company: Scalars['String'];
  company_logo?: InputMaybe<Scalars['Upload']>;
  contact: Scalars['String'];
  countries: Array<Scalars['String']>;
  date: Scalars['String'];
  email: Scalars['Email'];
  flower: Scalars['Uuid'];
  image: Scalars['Upload'];
  phone: Scalars['String'];
  product_info: Scalars['String'];
  target: Array<Scalars['String']>;
  title: Scalars['String'];
  video?: InputMaybe<Scalars['Upload']>;
};


export type MutationSubmitFormArgs = {
  attachments?: InputMaybe<Array<FormFieldAttachment>>;
  form_data: Scalars['String'];
  form_id: Scalars['ID'];
  g_recaptcha_response?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCollectionArgs = {
  id: Scalars['Uuid'];
  title: Scalars['String'];
};


export type MutationUpdatePersonalDetailsArgs = {
  address: AddressInput;
  email: Scalars['Email'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};


export type MutationUpdatePreferencesArgs = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['Email']>;
  locale?: InputMaybe<Scalars['String']>;
  magazine_digital?: InputMaybe<Scalars['Boolean']>;
  magazine_print?: InputMaybe<Scalars['Boolean']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
};

export type Page = {
  __typename?: 'Page';
  active_image?: Maybe<Scalars['Image']>;
  children?: Maybe<Array<Maybe<Page>>>;
  images: Array<Scalars['Image']>;
  parent?: Maybe<Page>;
  seo?: Maybe<Seo>;
  slug: Scalars['String'];
  template?: Maybe<Template>;
  template_name: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Picture = {
  __typename?: 'Picture';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  flowers: Array<Flower>;
  flowers_count?: Maybe<Scalars['Int']>;
  flowers_primary: Array<Flower>;
  flowers_primary_count?: Maybe<Scalars['Int']>;
  hash_sha1?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Image']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  seasons: Array<Scalars['String']>;
  themes: Array<Theme>;
  themes_count?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['Uuid'];
};

export type PicturePagination = {
  __typename?: 'PicturePagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data: Array<Picture>;
  /** Facet distribution */
  facet_distribution?: Maybe<Scalars['FacetDistribution']>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  allFlowers: Array<Flower>;
  categories: Array<Category>;
  collection?: Maybe<Collection>;
  collections: Array<Collection>;
  currentTenantInfo?: Maybe<Tenant_3af8041b6556776eda1312e7c8acaed7>;
  events?: Maybe<Array<Maybe<Event>>>;
  faq: Array<FrequentlyAskedQuestion>;
  featuredFlowerCultivars: Array<FlowerCultivar>;
  flowerCultivar?: Maybe<FlowerCultivar>;
  flowerCultivars: FlowerCultivarPagination;
  flowerDay?: Maybe<FlowerDayResult>;
  flowerPictures: Array<Picture>;
  flowers: Array<FlowerDay>;
  flowersAlphabet: Array<FlowerAlphabetGroup>;
  form?: Maybe<Form>;
  me?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  menu?: Maybe<Menu>;
  menus?: Maybe<Array<Maybe<Menu>>>;
  page?: Maybe<Page>;
  picture?: Maybe<Picture>;
  pictures: PicturePagination;
  search: Array<SearchResult>;
  settings: Array<Setting>;
  stories?: Maybe<StoryPagination>;
  story?: Maybe<Story>;
  suppliers?: Maybe<SupplierPagination>;
  themes: Array<Theme>;
  trendingThemes: Array<Theme>;
};


export type QueryCategoriesArgs = {
  hierarchy?: InputMaybe<Scalars['Boolean']>;
  resource_id?: InputMaybe<Scalars['ID']>;
};


export type QueryCollectionArgs = {
  id?: InputMaybe<Scalars['Uuid']>;
};


export type QueryEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryFaqArgs = {
  type: FaqTypeEnum;
};


export type QueryFeaturedFlowerCultivarsArgs = {
  colors?: InputMaybe<Array<FlowerCultivarColorEnum>>;
  flower_id: Scalars['Uuid'];
};


export type QueryFlowerCultivarArgs = {
  id: Scalars['Uuid'];
};


export type QueryFlowerCultivarsArgs = {
  colors?: InputMaybe<Array<FlowerCultivarColorEnum>>;
  flower_id: Scalars['Uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryFlowerDayArgs = {
  date?: InputMaybe<Scalars['Date']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryFlowerPicturesArgs = {
  flower_id?: InputMaybe<Scalars['Uuid']>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryFlowersArgs = {
  date_end: Scalars['Date'];
  date_start: Scalars['Date'];
};


export type QueryFlowersAlphabetArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryFormArgs = {
  id: Scalars['ID'];
};


export type QueryMenuArgs = {
  name: Scalars['String'];
};


export type QueryMenusArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPageArgs = {
  segments?: InputMaybe<Scalars['String']>;
};


export type QueryPictureArgs = {
  id: Scalars['Uuid'];
};


export type QueryPicturesArgs = {
  flower_ids?: InputMaybe<Array<Scalars['Uuid']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  seasons?: InputMaybe<Array<SeasonEnum>>;
  theme_slugs?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QueryStoriesArgs = {
  category_slugs?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryStoryArgs = {
  slug: Scalars['String'];
};


export type QuerySuppliersArgs = {
  latitude?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Int']>;
  longitude?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SupplierTypeEnum>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type SearchResult = Flower | FlowerCultivar | Story;

export enum SeasonEnum {
  Autumn = 'autumn',
  Spring = 'spring',
  Summer = 'summer',
  Winter = 'winter'
}

export type Seo = {
  __typename?: 'Seo';
  meta_description?: Maybe<Scalars['String']>;
  meta_follow?: Maybe<Scalars['Boolean']>;
  meta_index?: Maybe<Scalars['Boolean']>;
  meta_title?: Maybe<Scalars['String']>;
};

export type Setting = {
  __typename?: 'Setting';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Mixed']>;
};

export type Story = {
  __typename?: 'Story';
  active_image?: Maybe<Scalars['Image']>;
  capabilities: Array<Capability>;
  categories: Array<Category>;
  content_body: Scalars['String'];
  content_intro: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  facebook_post_id?: Maybe<Scalars['String']>;
  flowers: Array<Flower>;
  flowers_count?: Maybe<Scalars['Int']>;
  image_flower_detail?: Maybe<Scalars['Image']>;
  images: Array<Scalars['Image']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  /** Vul in om een verhaal te publiceren, deze is zichtbaar vanaf de publicatie datum */
  published_at?: Maybe<Scalars['Date']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String'];
  subject_name: Scalars['String'];
  subject_quote?: Maybe<Scalars['String']>;
  subject_title: Scalars['String'];
  title: Scalars['String'];
  title_overview: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  uuid: Scalars['Uuid'];
};

export type StoryPagination = {
  __typename?: 'StoryPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data: Array<Story>;
  /** Facet distribution */
  facet_distribution?: Maybe<Scalars['FacetDistribution']>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export type Supplier = {
  __typename?: 'Supplier';
  address?: Maybe<Address>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  url_de?: Maybe<Scalars['String']>;
  url_en?: Maybe<Scalars['String']>;
  url_fr?: Maybe<Scalars['String']>;
  url_nl?: Maybe<Scalars['String']>;
  uuid: Scalars['Uuid'];
};

export type SupplierPagination = {
  __typename?: 'SupplierPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data: Array<Supplier>;
  /** Facet distribution */
  facet_distribution?: Maybe<Scalars['FacetDistribution']>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export enum SupplierTypeEnum {
  Auction = 'Auction',
  CashAndCarry = 'CashAndCarry',
  Exporter = 'Exporter',
  HaulierProvidingRegularFloralDelivery = 'HaulierProvidingRegularFloralDelivery',
  LocalWholesale = 'LocalWholesale',
  Unspecified = 'Unspecified'
}

export type Template = TemplateStandaard | TemplateText;

export type TemplateStandaard = {
  __typename?: 'TemplateStandaard';
  capabilities: Array<Capability>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  updated_at?: Maybe<Scalars['DateTime']>;
  video: Array<Scalars['PrivateFile']>;
};

export type TemplateText = {
  __typename?: 'TemplateText';
  capabilities: Array<Capability>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  active_image?: Maybe<Scalars['Image']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  domain: Scalars['String'];
  id: Scalars['Int'];
  images: Array<Scalars['Image']>;
  locales: Array<Scalars['String']>;
  seo?: Maybe<Seo>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Tenant_3af8041b6556776eda1312e7c8acaed7 = {
  __typename?: 'Tenant_3af8041b6556776eda1312e7c8acaed7';
  active_image?: Maybe<Scalars['Image']>;
  seo?: Maybe<Seo>;
  title: Scalars['String'];
};

export type Theme = {
  __typename?: 'Theme';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  is_trending?: Maybe<Scalars['Boolean']>;
  locale: Scalars['String'];
  locales: Array<Theme>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['Uuid'];
};

export type TwoFactorSecret = {
  __typename?: 'TwoFactorSecret';
  app: Scalars['String'];
  email: Scalars['Email'];
  secret: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _?: Maybe<Scalars['String']>;
};

export enum UserSpecialtyEnum {
  Exporter = 'Exporter',
  Floriculturist = 'Floriculturist',
  Florist = 'Florist',
  IndustryPartner = 'IndustryPartner'
}

export type User_07e75d37dfdb1fad1b23e74888c041b4 = {
  __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4';
  active_image?: Maybe<Scalars['Image']>;
  activecampaign_id?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  avatar: Scalars['String'];
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['Email'];
  first_name: Scalars['String'];
  images: Array<Scalars['Image']>;
  last_name: Scalars['String'];
  locale: Scalars['String'];
  magazine_digital?: Maybe<Scalars['Boolean']>;
  magazine_print?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  newsletter?: Maybe<Scalars['Boolean']>;
  published: Scalars['Boolean'];
  role?: Maybe<Role>;
  role_id: Scalars['ID'];
  specialty: Scalars['String'];
  supplier?: Maybe<Supplier>;
  tenants: Array<Tenant>;
  tenants_count?: Maybe<Scalars['Int']>;
  two_factor_enabled?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['Uuid'];
};


export type User_07e75d37dfdb1fad1b23e74888c041b4AvatarArgs = {
  w?: InputMaybe<Scalars['Int']>;
};

export type SubmitCampaignRequestMutationVariables = Exact<{
  title: Scalars['String'];
  flower: Scalars['Uuid'];
  date: Scalars['String'];
  image: Scalars['Upload'];
  video?: InputMaybe<Scalars['Upload']>;
  company_logo?: InputMaybe<Scalars['Upload']>;
  target: Array<Scalars['String']> | Scalars['String'];
  contact: Scalars['String'];
  email: Scalars['Email'];
  phone: Scalars['String'];
  product_info: Scalars['String'];
  countries: Array<Scalars['String']> | Scalars['String'];
  company: Scalars['String'];
  captcha: Scalars['String'];
}>;


export type SubmitCampaignRequestMutation = { __typename?: 'Mutation', submitCampaignRequest: { __typename?: 'CampaignRequest', title: string } };

export type AddToCollectionMutationVariables = Exact<{
  flowerCultivarIds?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  flowerIds?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  id?: InputMaybe<Scalars['Uuid']>;
}>;


export type AddToCollectionMutation = { __typename?: 'Mutation', addToCollection: Array<{ __typename?: 'CollectionEntry', uuid: string, flower: { __typename?: 'Flower', uuid: string, slug: string, title: string, title_latin: string, image_primary?: Image | null }, flower_cultivar?: { __typename?: 'FlowerCultivar', uuid: string, color?: string | null, title: string } | null }> };

export type CreateCollectionMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateCollectionMutation = { __typename?: 'Mutation', createCollection: { __typename?: 'Collection', uuid: string, created_at?: string | null, entries_count?: number | null, image?: Image | null, title: string } };

export type MailCollectionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Uuid']>;
  email: Scalars['Email'];
}>;


export type MailCollectionMutation = { __typename?: 'Mutation', mailCollection?: null | null };

export type OrderCollectionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Uuid']>;
  supplierId: Scalars['Uuid'];
}>;


export type OrderCollectionMutation = { __typename?: 'Mutation', orderCollection: { __typename?: 'CollectionOrderResult', url: string, collection: { __typename?: 'Collection', uuid: string, entries: Array<{ __typename?: 'CollectionEntry', uuid: string, flower: { __typename?: 'Flower', uuid: string, title_latin: string }, flower_cultivar?: { __typename?: 'FlowerCultivar', uuid: string, title: string } | null }> }, supplier: { __typename?: 'Supplier', uuid: string, title: string } } };

export type RemoveCollectionMutationVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type RemoveCollectionMutation = { __typename?: 'Mutation', removeCollection?: null | null };

export type RemoveFromCollectionMutationVariables = Exact<{
  flowerCultivarIds?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  flowerIds?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  id?: InputMaybe<Scalars['Uuid']>;
}>;


export type RemoveFromCollectionMutation = { __typename?: 'Mutation', removeFromCollection?: null | null };

export type UpdateCollectionMutationVariables = Exact<{
  id: Scalars['Uuid'];
  title: Scalars['String'];
}>;


export type UpdateCollectionMutation = { __typename?: 'Mutation', updateCollection: { __typename?: 'Collection', uuid: string, title: string } };

export type EventSignUpMutationVariables = Exact<{
  event: Scalars['Uuid'];
  email: Scalars['Email'];
}>;


export type EventSignUpMutation = { __typename?: 'Mutation', eventSignUp?: boolean | null };

export type ConsolidateUserFavoritesOnAuthenticatedMutationVariables = Exact<{ [key: string]: never; }>;


export type ConsolidateUserFavoritesOnAuthenticatedMutation = { __typename?: 'Mutation', consolidateUserFavoritesOnAuthenticated: boolean };

export type RegisterMutationVariables = Exact<{
  address?: InputMaybe<AddressInput>;
  email: Scalars['Email'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  locale: Scalars['String'];
  magazine_print?: InputMaybe<Scalars['Boolean']>;
  magazine_digital?: InputMaybe<Scalars['Boolean']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  specialty: UserSpecialtyEnum;
  captcha: Scalars['String'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register?: null | null };

export type UpdatePersonalDetailsMutationVariables = Exact<{
  address: AddressInput;
  email: Scalars['Email'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
}>;


export type UpdatePersonalDetailsMutation = { __typename?: 'Mutation', updatePersonalDetails?: null | null };

export type UpdatePreferencesMutationVariables = Exact<{
  address?: InputMaybe<AddressInput>;
  email: Scalars['Email'];
  locale: Scalars['String'];
  magazine_digital?: InputMaybe<Scalars['Boolean']>;
  magazine_print?: InputMaybe<Scalars['Boolean']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdatePreferencesMutation = { __typename?: 'Mutation', updatePreferences?: null | null };

export type AllFlowerNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFlowerNamesQuery = { __typename?: 'Query', allFlowers: Array<{ __typename?: 'Flower', uuid: string, title: string, title_latin: string }> };

export type AllFlowersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFlowersQuery = { __typename?: 'Query', allFlowers: Array<{ __typename?: 'Flower', uuid: string, slug: string, title: string, title_latin: string, availability: any }> };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: number, name: string, slug: string }> };

export type CollectionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Uuid']>;
}>;


export type CollectionQuery = { __typename?: 'Query', collection?: { __typename?: 'Collection', uuid: string, created_at?: string | null, title: string, entries: Array<{ __typename?: 'CollectionEntry', uuid: string, flower: { __typename?: 'Flower', uuid: string, availability: any, slug: string, title: string, title_latin: string, image_primary?: Image | null }, flower_cultivar?: { __typename?: 'FlowerCultivar', uuid: string, active_image?: Image | null, color?: string | null, color_hex?: string | null, color_rhs?: string | null, title: string } | null }> } | null };

export type CollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CollectionsQuery = { __typename?: 'Query', collections: Array<{ __typename?: 'Collection', uuid: string, created_at?: string | null, entries_count?: number | null, image?: Image | null, title: string }> };

export type EventsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type EventsQuery = { __typename?: 'Query', events?: Array<{ __typename?: 'Event', uuid: string, title: string, description?: string | null, time_start?: string | null, time_end?: string | null, date?: string | null, location?: string | null, address?: { __typename?: 'Address', title: string, city?: string | null, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null, country?: string | null } | null } | null> | null };

export type FaqQueryVariables = Exact<{
  type: FaqTypeEnum;
}>;


export type FaqQuery = { __typename?: 'Query', faq: Array<{ __typename?: 'FrequentlyAskedQuestion', title: string, answer: string }> };

export type FeaturedFlowerCultivarsQueryVariables = Exact<{
  colors?: InputMaybe<Array<FlowerCultivarColorEnum> | FlowerCultivarColorEnum>;
  flowerId: Scalars['Uuid'];
}>;


export type FeaturedFlowerCultivarsQuery = { __typename?: 'Query', featuredFlowerCultivars: Array<{ __typename?: 'FlowerCultivar', uuid: string, active_image?: Image | null, color?: string | null, color_hex?: string | null, color_rhs?: string | null, registered_at: string, title: string }> };

export type FlowerCultivarQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type FlowerCultivarQuery = { __typename?: 'Query', flowerCultivar?: { __typename?: 'FlowerCultivar', uuid: string, active_image?: Image | null, color?: string | null, color_hex?: string | null, color_rhs?: string | null, registered_at: string, title: string } | null };

export type FlowerCultivarsQueryVariables = Exact<{
  colors?: InputMaybe<Array<FlowerCultivarColorEnum> | FlowerCultivarColorEnum>;
  flowerId: Scalars['Uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
}>;


export type FlowerCultivarsQuery = { __typename?: 'Query', flowerCultivars: { __typename?: 'FlowerCultivarPagination', current_page: number, facet_distribution?: any | null, has_more_pages: boolean, total: number, data: Array<{ __typename?: 'FlowerCultivar', uuid: string, active_image?: Image | null, color?: string | null, color_hex?: string | null, color_rhs?: string | null, registered_at: string, title: string }> } };

export type FlowerDayQueryVariables = Exact<{
  date?: InputMaybe<Scalars['Date']>;
  slug?: InputMaybe<Scalars['String']>;
}>;


export type FlowerDayQuery = { __typename?: 'Query', flowerDay?: { __typename?: 'FlowerDayResult', date: string, image_primary_override?: Image | null, flower: { __typename?: 'Flower', uuid: string, about_colors?: string | null, about_style: string, about_symbolism: string, advice_care_tips?: string | null, advice_meaning?: string | null, advice_presentation?: string | null, advice_sustainability?: string | null, advice_versatility?: string | null, availability: any, care_nutrition?: string | null, care_sun?: string | null, care_cut?: string | null, care_prune?: string | null, care_vase?: string | null, care_water?: string | null, florists_count?: number | null, image_primary?: Image | null, images_additional: Array<Image>, inspiration_intro?: string | null, intro: string, origin: string, origin_countries_count?: number | null, origin_top_countries: Array<string>, slug: string, techniques_arrangement?: string | null, techniques_bouquets?: string | null, techniques_drying?: string | null, techniques_mono?: string | null, title: string, title_latin: string, seo?: { __typename?: 'Seo', meta_description?: string | null, meta_follow?: boolean | null, meta_index?: boolean | null, meta_title?: string | null } | null }, next?: { __typename?: 'FlowerDay', date: string, flower: { __typename?: 'Flower', uuid: string, slug: string } } | null, previous?: { __typename?: 'FlowerDay', date: string, flower: { __typename?: 'Flower', uuid: string, slug: string } } | null } | null };

export type FlowerPicturesQueryVariables = Exact<{
  flowerId: Scalars['Uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type FlowerPicturesQuery = { __typename?: 'Query', flowerPictures: Array<{ __typename?: 'Picture', uuid: string, image?: Image | null, seasons: Array<string>, flowers_primary: Array<{ __typename?: 'Flower', uuid: string, title_latin: string }>, flowers: Array<{ __typename?: 'Flower', uuid: string, slug: string, title_latin: string }>, themes: Array<{ __typename?: 'Theme', uuid: string, locales: Array<{ __typename?: 'Theme', locale: string, slug: string, title: string }> }> }> };

export type FlowersQueryVariables = Exact<{
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
}>;


export type FlowersQuery = { __typename?: 'Query', flowers: Array<{ __typename?: 'FlowerDay', uuid: string, date: string, image_primary_override?: Image | null, flower: { __typename?: 'Flower', uuid: string, availability: any, image_primary?: Image | null, slug: string, title: string, title_latin: string } }> };

export type FlowersAlphabetQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;


export type FlowersAlphabetQuery = { __typename?: 'Query', flowersAlphabet: Array<{ __typename?: 'FlowerAlphabetGroup', letter: string, flowers: Array<{ __typename?: 'Flower', uuid: string, availability: any, image_primary?: Image | null, slug: string, title: string, title_latin: string }> }> };

export type PictureQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type PictureQuery = { __typename?: 'Query', picture?: { __typename?: 'Picture', uuid: string, image?: Image | null, seasons: Array<string>, flowers: Array<{ __typename?: 'Flower', uuid: string, slug: string, title_latin: string }>, themes: Array<{ __typename?: 'Theme', uuid: string, locales: Array<{ __typename?: 'Theme', locale: string, slug: string, title: string }> }> } | null };

export type PicturesQueryVariables = Exact<{
  flowerIds?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  seasons?: InputMaybe<Array<SeasonEnum> | SeasonEnum>;
  themeSlugs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PicturesQuery = { __typename?: 'Query', pictures: { __typename?: 'PicturePagination', current_page: number, has_more_pages: boolean, per_page: number, total: number, data: Array<{ __typename?: 'Picture', uuid: string, image?: Image | null, seasons: Array<string>, flowers: Array<{ __typename?: 'Flower', uuid: string, slug: string, title_latin: string }>, themes: Array<{ __typename?: 'Theme', uuid: string, locales: Array<{ __typename?: 'Theme', locale: string, slug: string, title: string }> }> }> } };

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type SearchQuery = { __typename?: 'Query', search: Array<{ __typename: 'Flower', uuid: string, slug: string, title_latin: string } | { __typename: 'FlowerCultivar', uuid: string, title: string, flower: { __typename?: 'Flower', uuid: string, slug: string } } | { __typename: 'Story', uuid: string, slug: string, title_overview: string }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4', uuid: string, email: string, first_name: string, last_name: string, locale: string, magazine_digital?: boolean | null, magazine_print?: boolean | null, newsletter?: boolean | null, specialty: string, address?: { __typename?: 'Address', city?: string | null, house_number?: string | null, house_number_suffix?: string | null, latitude?: number | null, longitude?: number | null, zipcode?: string | null, country_code?: string | null, street: string } | null, supplier?: { __typename?: 'Supplier', uuid: string } | null } | null };

export type PageQueryVariables = Exact<{
  segments: Scalars['String'];
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', active_image?: Image | null, slug: string, title: string, url: string, seo?: { __typename?: 'Seo', meta_title?: string | null, meta_description?: string | null, meta_index?: boolean | null, meta_follow?: boolean | null } | null, template?: { __typename: 'TemplateStandaard', content?: string | null, video: Array<any> } | { __typename: 'TemplateText', content?: string | null } | null } | null };

export type StoriesQueryVariables = Exact<{
  categorySlugs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
}>;


export type StoriesQuery = { __typename?: 'Query', stories?: { __typename?: 'StoryPagination', current_page: number, per_page: number, total: number, data: Array<{ __typename?: 'Story', uuid: string, active_image?: Image | null, content_intro: string, published_at?: string | null, image_flower_detail?: Image | null, slug: string, title: string, title_overview: string, categories: Array<{ __typename?: 'Category', id: number, name: string, slug: string }> }> } | null };

export type StoryQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type StoryQuery = { __typename?: 'Query', story?: { __typename?: 'Story', active_image?: Image | null, content_intro: string, content_body: string, published_at?: string | null, slug: string, subject_name: string, subject_quote?: string | null, subject_title: string, title: string, categories: Array<{ __typename?: 'Category', id: number, name: string, slug: string }> } | null };

export type SuppliersQueryVariables = Exact<{
  latitude?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Int']>;
  longitude?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SupplierTypeEnum>;
}>;


export type SuppliersQuery = { __typename?: 'Query', suppliers?: { __typename?: 'SupplierPagination', current_page: number, per_page: number, total: number, data: Array<{ __typename?: 'Supplier', uuid: string, title: string }> } | null };

export type ThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemesQuery = { __typename?: 'Query', themes: Array<{ __typename?: 'Theme', uuid: string, slug: string, title: string }> };

export type TrendingThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type TrendingThemesQuery = { __typename?: 'Query', trendingThemes: Array<{ __typename?: 'Theme', uuid: string, locales: Array<{ __typename?: 'Theme', locale: string, slug: string, title: string }> }> };


export const SubmitCampaignRequestDocument = gql`
    mutation submitCampaignRequest($title: String!, $flower: Uuid!, $date: String!, $image: Upload!, $video: Upload, $company_logo: Upload, $target: [String!]!, $contact: String!, $email: Email!, $phone: String!, $product_info: String!, $countries: [String!]!, $company: String!, $captcha: String!) {
  submitCampaignRequest(
    title: $title
    flower: $flower
    date: $date
    image: $image
    video: $video
    target: $target
    contact: $contact
    email: $email
    phone: $phone
    product_info: $product_info
    countries: $countries
    company_logo: $company_logo
    company: $company
    captcha: $captcha
  ) {
    title
  }
}
    `;

/**
 * __useSubmitCampaignRequestMutation__
 *
 * To run a mutation, you first call `useSubmitCampaignRequestMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCampaignRequestMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitCampaignRequestMutation({
 *   variables: {
 *     title: // value for 'title'
 *     flower: // value for 'flower'
 *     date: // value for 'date'
 *     image: // value for 'image'
 *     video: // value for 'video'
 *     company_logo: // value for 'company_logo'
 *     target: // value for 'target'
 *     contact: // value for 'contact'
 *     email: // value for 'email'
 *     phone: // value for 'phone'
 *     product_info: // value for 'product_info'
 *     countries: // value for 'countries'
 *     company: // value for 'company'
 *     captcha: // value for 'captcha'
 *   },
 * });
 */
export function useSubmitCampaignRequestMutation(options: VueApolloComposable.UseMutationOptions<SubmitCampaignRequestMutation, SubmitCampaignRequestMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitCampaignRequestMutation, SubmitCampaignRequestMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SubmitCampaignRequestMutation, SubmitCampaignRequestMutationVariables>(SubmitCampaignRequestDocument, options);
}
export type SubmitCampaignRequestMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SubmitCampaignRequestMutation, SubmitCampaignRequestMutationVariables>;
export const AddToCollectionDocument = gql`
    mutation addToCollection($flowerCultivarIds: [Uuid!], $flowerIds: [Uuid!], $id: Uuid) {
  addToCollection(
    flower_cultivar_ids: $flowerCultivarIds
    flower_ids: $flowerIds
    id: $id
  ) {
    uuid
    flower {
      uuid
      slug
      title
      title_latin
      image_primary
    }
    flower_cultivar {
      uuid
      color
      title
    }
  }
}
    `;

/**
 * __useAddToCollectionMutation__
 *
 * To run a mutation, you first call `useAddToCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddToCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddToCollectionMutation({
 *   variables: {
 *     flowerCultivarIds: // value for 'flowerCultivarIds'
 *     flowerIds: // value for 'flowerIds'
 *     id: // value for 'id'
 *   },
 * });
 */
export function useAddToCollectionMutation(options: VueApolloComposable.UseMutationOptions<AddToCollectionMutation, AddToCollectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddToCollectionMutation, AddToCollectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<AddToCollectionMutation, AddToCollectionMutationVariables>(AddToCollectionDocument, options);
}
export type AddToCollectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddToCollectionMutation, AddToCollectionMutationVariables>;
export const CreateCollectionDocument = gql`
    mutation createCollection($title: String!) {
  createCollection(title: $title) {
    uuid
    created_at
    entries_count
    image
    title
  }
}
    `;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCollectionMutation({
 *   variables: {
 *     title: // value for 'title'
 *   },
 * });
 */
export function useCreateCollectionMutation(options: VueApolloComposable.UseMutationOptions<CreateCollectionMutation, CreateCollectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCollectionMutation, CreateCollectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(CreateCollectionDocument, options);
}
export type CreateCollectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCollectionMutation, CreateCollectionMutationVariables>;
export const MailCollectionDocument = gql`
    mutation mailCollection($id: Uuid, $email: Email!) {
  mailCollection(id: $id, email: $email)
}
    `;

/**
 * __useMailCollectionMutation__
 *
 * To run a mutation, you first call `useMailCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useMailCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useMailCollectionMutation({
 *   variables: {
 *     id: // value for 'id'
 *     email: // value for 'email'
 *   },
 * });
 */
export function useMailCollectionMutation(options: VueApolloComposable.UseMutationOptions<MailCollectionMutation, MailCollectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<MailCollectionMutation, MailCollectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<MailCollectionMutation, MailCollectionMutationVariables>(MailCollectionDocument, options);
}
export type MailCollectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<MailCollectionMutation, MailCollectionMutationVariables>;
export const OrderCollectionDocument = gql`
    mutation orderCollection($id: Uuid, $supplierId: Uuid!) {
  orderCollection(id: $id, supplier_id: $supplierId) {
    collection {
      uuid
      entries {
        uuid
        flower {
          uuid
          title_latin
        }
        flower_cultivar {
          uuid
          title
        }
      }
    }
    supplier {
      uuid
      title
    }
    url
  }
}
    `;

/**
 * __useOrderCollectionMutation__
 *
 * To run a mutation, you first call `useOrderCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useOrderCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useOrderCollectionMutation({
 *   variables: {
 *     id: // value for 'id'
 *     supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useOrderCollectionMutation(options: VueApolloComposable.UseMutationOptions<OrderCollectionMutation, OrderCollectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<OrderCollectionMutation, OrderCollectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<OrderCollectionMutation, OrderCollectionMutationVariables>(OrderCollectionDocument, options);
}
export type OrderCollectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<OrderCollectionMutation, OrderCollectionMutationVariables>;
export const RemoveCollectionDocument = gql`
    mutation removeCollection($id: Uuid!) {
  removeCollection(id: $id)
}
    `;

/**
 * __useRemoveCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCollectionMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCollectionMutation(options: VueApolloComposable.UseMutationOptions<RemoveCollectionMutation, RemoveCollectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveCollectionMutation, RemoveCollectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveCollectionMutation, RemoveCollectionMutationVariables>(RemoveCollectionDocument, options);
}
export type RemoveCollectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveCollectionMutation, RemoveCollectionMutationVariables>;
export const RemoveFromCollectionDocument = gql`
    mutation removeFromCollection($flowerCultivarIds: [Uuid!], $flowerIds: [Uuid!], $id: Uuid) {
  removeFromCollection(
    flower_cultivar_ids: $flowerCultivarIds
    flower_ids: $flowerIds
    id: $id
  )
}
    `;

/**
 * __useRemoveFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveFromCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveFromCollectionMutation({
 *   variables: {
 *     flowerCultivarIds: // value for 'flowerCultivarIds'
 *     flowerIds: // value for 'flowerIds'
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFromCollectionMutation(options: VueApolloComposable.UseMutationOptions<RemoveFromCollectionMutation, RemoveFromCollectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveFromCollectionMutation, RemoveFromCollectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveFromCollectionMutation, RemoveFromCollectionMutationVariables>(RemoveFromCollectionDocument, options);
}
export type RemoveFromCollectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveFromCollectionMutation, RemoveFromCollectionMutationVariables>;
export const UpdateCollectionDocument = gql`
    mutation updateCollection($id: Uuid!, $title: String!) {
  updateCollection(id: $id, title: $title) {
    uuid
    title
  }
}
    `;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCollectionMutation({
 *   variables: {
 *     id: // value for 'id'
 *     title: // value for 'title'
 *   },
 * });
 */
export function useUpdateCollectionMutation(options: VueApolloComposable.UseMutationOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(UpdateCollectionDocument, options);
}
export type UpdateCollectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateCollectionMutation, UpdateCollectionMutationVariables>;
export const EventSignUpDocument = gql`
    mutation eventSignUp($event: Uuid!, $email: Email!) {
  eventSignUp(event: $event, email: $email)
}
    `;

/**
 * __useEventSignUpMutation__
 *
 * To run a mutation, you first call `useEventSignUpMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEventSignUpMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEventSignUpMutation({
 *   variables: {
 *     event: // value for 'event'
 *     email: // value for 'email'
 *   },
 * });
 */
export function useEventSignUpMutation(options: VueApolloComposable.UseMutationOptions<EventSignUpMutation, EventSignUpMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<EventSignUpMutation, EventSignUpMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<EventSignUpMutation, EventSignUpMutationVariables>(EventSignUpDocument, options);
}
export type EventSignUpMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<EventSignUpMutation, EventSignUpMutationVariables>;
export const ConsolidateUserFavoritesOnAuthenticatedDocument = gql`
    mutation consolidateUserFavoritesOnAuthenticated {
  consolidateUserFavoritesOnAuthenticated
}
    `;

/**
 * __useConsolidateUserFavoritesOnAuthenticatedMutation__
 *
 * To run a mutation, you first call `useConsolidateUserFavoritesOnAuthenticatedMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useConsolidateUserFavoritesOnAuthenticatedMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useConsolidateUserFavoritesOnAuthenticatedMutation();
 */
export function useConsolidateUserFavoritesOnAuthenticatedMutation(options: VueApolloComposable.UseMutationOptions<ConsolidateUserFavoritesOnAuthenticatedMutation, ConsolidateUserFavoritesOnAuthenticatedMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ConsolidateUserFavoritesOnAuthenticatedMutation, ConsolidateUserFavoritesOnAuthenticatedMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ConsolidateUserFavoritesOnAuthenticatedMutation, ConsolidateUserFavoritesOnAuthenticatedMutationVariables>(ConsolidateUserFavoritesOnAuthenticatedDocument, options);
}
export type ConsolidateUserFavoritesOnAuthenticatedMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ConsolidateUserFavoritesOnAuthenticatedMutation, ConsolidateUserFavoritesOnAuthenticatedMutationVariables>;
export const RegisterDocument = gql`
    mutation register($address: AddressInput, $email: Email!, $first_name: String!, $last_name: String!, $locale: String!, $magazine_print: Boolean, $magazine_digital: Boolean, $newsletter: Boolean, $specialty: UserSpecialtyEnum!, $captcha: String!) {
  register(
    address: $address
    email: $email
    first_name: $first_name
    last_name: $last_name
    locale: $locale
    magazine_print: $magazine_print
    magazine_digital: $magazine_digital
    newsletter: $newsletter
    specialty: $specialty
    captcha: $captcha
  )
}
    `;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRegisterMutation({
 *   variables: {
 *     address: // value for 'address'
 *     email: // value for 'email'
 *     first_name: // value for 'first_name'
 *     last_name: // value for 'last_name'
 *     locale: // value for 'locale'
 *     magazine_print: // value for 'magazine_print'
 *     magazine_digital: // value for 'magazine_digital'
 *     newsletter: // value for 'newsletter'
 *     specialty: // value for 'specialty'
 *     captcha: // value for 'captcha'
 *   },
 * });
 */
export function useRegisterMutation(options: VueApolloComposable.UseMutationOptions<RegisterMutation, RegisterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RegisterMutation, RegisterMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
}
export type RegisterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RegisterMutation, RegisterMutationVariables>;
export const UpdatePersonalDetailsDocument = gql`
    mutation updatePersonalDetails($address: AddressInput!, $email: Email!, $first_name: String!, $last_name: String!) {
  updatePersonalDetails(
    address: $address
    email: $email
    first_name: $first_name
    last_name: $last_name
  )
}
    `;

/**
 * __useUpdatePersonalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalDetailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalDetailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePersonalDetailsMutation({
 *   variables: {
 *     address: // value for 'address'
 *     email: // value for 'email'
 *     first_name: // value for 'first_name'
 *     last_name: // value for 'last_name'
 *   },
 * });
 */
export function useUpdatePersonalDetailsMutation(options: VueApolloComposable.UseMutationOptions<UpdatePersonalDetailsMutation, UpdatePersonalDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePersonalDetailsMutation, UpdatePersonalDetailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdatePersonalDetailsMutation, UpdatePersonalDetailsMutationVariables>(UpdatePersonalDetailsDocument, options);
}
export type UpdatePersonalDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePersonalDetailsMutation, UpdatePersonalDetailsMutationVariables>;
export const UpdatePreferencesDocument = gql`
    mutation updatePreferences($address: AddressInput, $email: Email!, $locale: String!, $magazine_digital: Boolean, $magazine_print: Boolean, $newsletter: Boolean) {
  updatePreferences(
    address: $address
    email: $email
    locale: $locale
    magazine_digital: $magazine_digital
    magazine_print: $magazine_print
    newsletter: $newsletter
  )
}
    `;

/**
 * __useUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePreferencesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferencesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePreferencesMutation({
 *   variables: {
 *     address: // value for 'address'
 *     email: // value for 'email'
 *     locale: // value for 'locale'
 *     magazine_digital: // value for 'magazine_digital'
 *     magazine_print: // value for 'magazine_print'
 *     newsletter: // value for 'newsletter'
 *   },
 * });
 */
export function useUpdatePreferencesMutation(options: VueApolloComposable.UseMutationOptions<UpdatePreferencesMutation, UpdatePreferencesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>(UpdatePreferencesDocument, options);
}
export type UpdatePreferencesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>;
export const AllFlowerNamesDocument = gql`
    query allFlowerNames {
  allFlowers {
    uuid
    title
    title_latin
  }
}
    `;

/**
 * __useAllFlowerNamesQuery__
 *
 * To run a query within a Vue component, call `useAllFlowerNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFlowerNamesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAllFlowerNamesQuery();
 */
export function useAllFlowerNamesQuery(options: VueApolloComposable.UseQueryOptions<AllFlowerNamesQuery, AllFlowerNamesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AllFlowerNamesQuery, AllFlowerNamesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AllFlowerNamesQuery, AllFlowerNamesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AllFlowerNamesQuery, AllFlowerNamesQueryVariables>(AllFlowerNamesDocument, {}, options);
}
export function useAllFlowerNamesLazyQuery(options: VueApolloComposable.UseQueryOptions<AllFlowerNamesQuery, AllFlowerNamesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AllFlowerNamesQuery, AllFlowerNamesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AllFlowerNamesQuery, AllFlowerNamesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AllFlowerNamesQuery, AllFlowerNamesQueryVariables>(AllFlowerNamesDocument, {}, options);
}
export type AllFlowerNamesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AllFlowerNamesQuery, AllFlowerNamesQueryVariables>;
export const AllFlowersDocument = gql`
    query allFlowers {
  allFlowers {
    uuid
    slug
    title
    title_latin
    availability
  }
}
    `;

/**
 * __useAllFlowersQuery__
 *
 * To run a query within a Vue component, call `useAllFlowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFlowersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAllFlowersQuery();
 */
export function useAllFlowersQuery(options: VueApolloComposable.UseQueryOptions<AllFlowersQuery, AllFlowersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AllFlowersQuery, AllFlowersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AllFlowersQuery, AllFlowersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AllFlowersQuery, AllFlowersQueryVariables>(AllFlowersDocument, {}, options);
}
export function useAllFlowersLazyQuery(options: VueApolloComposable.UseQueryOptions<AllFlowersQuery, AllFlowersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AllFlowersQuery, AllFlowersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AllFlowersQuery, AllFlowersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AllFlowersQuery, AllFlowersQueryVariables>(AllFlowersDocument, {}, options);
}
export type AllFlowersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AllFlowersQuery, AllFlowersQueryVariables>;
export const CategoriesDocument = gql`
    query categories {
  categories(hierarchy: false) {
    id
    name
    slug
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a Vue component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCategoriesQuery();
 */
export function useCategoriesQuery(options: VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, {}, options);
}
export function useCategoriesLazyQuery(options: VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, {}, options);
}
export type CategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CategoriesQuery, CategoriesQueryVariables>;
export const CollectionDocument = gql`
    query collection($id: Uuid) {
  collection(id: $id) {
    uuid
    created_at
    title
    entries {
      uuid
      flower {
        uuid
        availability
        slug
        title
        title_latin
        image_primary
      }
      flower_cultivar {
        uuid
        active_image
        color
        color_hex
        color_rhs
        title
      }
    }
  }
}
    `;

/**
 * __useCollectionQuery__
 *
 * To run a query within a Vue component, call `useCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCollectionQuery({
 *   id: // value for 'id'
 * });
 */
export function useCollectionQuery(variables: CollectionQueryVariables | VueCompositionApi.Ref<CollectionQueryVariables> | ReactiveFunction<CollectionQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, variables, options);
}
export function useCollectionLazyQuery(variables: CollectionQueryVariables | VueCompositionApi.Ref<CollectionQueryVariables> | ReactiveFunction<CollectionQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, variables, options);
}
export type CollectionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CollectionQuery, CollectionQueryVariables>;
export const CollectionsDocument = gql`
    query collections {
  collections {
    uuid
    created_at
    entries_count
    image
    title
  }
}
    `;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a Vue component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCollectionsQuery();
 */
export function useCollectionsQuery(options: VueApolloComposable.UseQueryOptions<CollectionsQuery, CollectionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CollectionsQuery, CollectionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CollectionsQuery, CollectionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, {}, options);
}
export function useCollectionsLazyQuery(options: VueApolloComposable.UseQueryOptions<CollectionsQuery, CollectionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CollectionsQuery, CollectionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CollectionsQuery, CollectionsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, {}, options);
}
export type CollectionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CollectionsQuery, CollectionsQueryVariables>;
export const EventsDocument = gql`
    query events($limit: Int) {
  events(limit: $limit) {
    uuid
    title
    description
    time_start
    time_end
    date
    location
    address {
      title
      city
      house_number
      house_number_suffix
      zipcode
      country
    }
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a Vue component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventsQuery({
 *   limit: // value for 'limit'
 * });
 */
export function useEventsQuery(variables: EventsQueryVariables | VueCompositionApi.Ref<EventsQueryVariables> | ReactiveFunction<EventsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, variables, options);
}
export function useEventsLazyQuery(variables: EventsQueryVariables | VueCompositionApi.Ref<EventsQueryVariables> | ReactiveFunction<EventsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, variables, options);
}
export type EventsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EventsQuery, EventsQueryVariables>;
export const FaqDocument = gql`
    query faq($type: FaqTypeEnum!) {
  faq(type: $type) {
    title
    answer
  }
}
    `;

/**
 * __useFaqQuery__
 *
 * To run a query within a Vue component, call `useFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFaqQuery({
 *   type: // value for 'type'
 * });
 */
export function useFaqQuery(variables: FaqQueryVariables | VueCompositionApi.Ref<FaqQueryVariables> | ReactiveFunction<FaqQueryVariables>, options: VueApolloComposable.UseQueryOptions<FaqQuery, FaqQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FaqQuery, FaqQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FaqQuery, FaqQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FaqQuery, FaqQueryVariables>(FaqDocument, variables, options);
}
export function useFaqLazyQuery(variables: FaqQueryVariables | VueCompositionApi.Ref<FaqQueryVariables> | ReactiveFunction<FaqQueryVariables>, options: VueApolloComposable.UseQueryOptions<FaqQuery, FaqQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FaqQuery, FaqQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FaqQuery, FaqQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FaqQuery, FaqQueryVariables>(FaqDocument, variables, options);
}
export type FaqQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FaqQuery, FaqQueryVariables>;
export const FeaturedFlowerCultivarsDocument = gql`
    query featuredFlowerCultivars($colors: [FlowerCultivarColorEnum!], $flowerId: Uuid!) {
  featuredFlowerCultivars(colors: $colors, flower_id: $flowerId) {
    uuid
    active_image
    color
    color_hex
    color_rhs
    registered_at
    title
  }
}
    `;

/**
 * __useFeaturedFlowerCultivarsQuery__
 *
 * To run a query within a Vue component, call `useFeaturedFlowerCultivarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedFlowerCultivarsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFeaturedFlowerCultivarsQuery({
 *   colors: // value for 'colors'
 *   flowerId: // value for 'flowerId'
 * });
 */
export function useFeaturedFlowerCultivarsQuery(variables: FeaturedFlowerCultivarsQueryVariables | VueCompositionApi.Ref<FeaturedFlowerCultivarsQueryVariables> | ReactiveFunction<FeaturedFlowerCultivarsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables>(FeaturedFlowerCultivarsDocument, variables, options);
}
export function useFeaturedFlowerCultivarsLazyQuery(variables: FeaturedFlowerCultivarsQueryVariables | VueCompositionApi.Ref<FeaturedFlowerCultivarsQueryVariables> | ReactiveFunction<FeaturedFlowerCultivarsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables>(FeaturedFlowerCultivarsDocument, variables, options);
}
export type FeaturedFlowerCultivarsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FeaturedFlowerCultivarsQuery, FeaturedFlowerCultivarsQueryVariables>;
export const FlowerCultivarDocument = gql`
    query flowerCultivar($id: Uuid!) {
  flowerCultivar(id: $id) {
    uuid
    active_image
    color
    color_hex
    color_rhs
    registered_at
    title
  }
}
    `;

/**
 * __useFlowerCultivarQuery__
 *
 * To run a query within a Vue component, call `useFlowerCultivarQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowerCultivarQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFlowerCultivarQuery({
 *   id: // value for 'id'
 * });
 */
export function useFlowerCultivarQuery(variables: FlowerCultivarQueryVariables | VueCompositionApi.Ref<FlowerCultivarQueryVariables> | ReactiveFunction<FlowerCultivarQueryVariables>, options: VueApolloComposable.UseQueryOptions<FlowerCultivarQuery, FlowerCultivarQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowerCultivarQuery, FlowerCultivarQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowerCultivarQuery, FlowerCultivarQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FlowerCultivarQuery, FlowerCultivarQueryVariables>(FlowerCultivarDocument, variables, options);
}
export function useFlowerCultivarLazyQuery(variables: FlowerCultivarQueryVariables | VueCompositionApi.Ref<FlowerCultivarQueryVariables> | ReactiveFunction<FlowerCultivarQueryVariables>, options: VueApolloComposable.UseQueryOptions<FlowerCultivarQuery, FlowerCultivarQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowerCultivarQuery, FlowerCultivarQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowerCultivarQuery, FlowerCultivarQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FlowerCultivarQuery, FlowerCultivarQueryVariables>(FlowerCultivarDocument, variables, options);
}
export type FlowerCultivarQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FlowerCultivarQuery, FlowerCultivarQueryVariables>;
export const FlowerCultivarsDocument = gql`
    query flowerCultivars($colors: [FlowerCultivarColorEnum!], $flowerId: Uuid!, $limit: Int, $page: Int, $query: String) {
  flowerCultivars(
    colors: $colors
    flower_id: $flowerId
    limit: $limit
    page: $page
    query: $query
  ) {
    current_page
    data {
      uuid
      active_image
      color
      color_hex
      color_rhs
      registered_at
      title
    }
    facet_distribution
    has_more_pages
    total
  }
}
    `;

/**
 * __useFlowerCultivarsQuery__
 *
 * To run a query within a Vue component, call `useFlowerCultivarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowerCultivarsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFlowerCultivarsQuery({
 *   colors: // value for 'colors'
 *   flowerId: // value for 'flowerId'
 *   limit: // value for 'limit'
 *   page: // value for 'page'
 *   query: // value for 'query'
 * });
 */
export function useFlowerCultivarsQuery(variables: FlowerCultivarsQueryVariables | VueCompositionApi.Ref<FlowerCultivarsQueryVariables> | ReactiveFunction<FlowerCultivarsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FlowerCultivarsQuery, FlowerCultivarsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowerCultivarsQuery, FlowerCultivarsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowerCultivarsQuery, FlowerCultivarsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FlowerCultivarsQuery, FlowerCultivarsQueryVariables>(FlowerCultivarsDocument, variables, options);
}
export function useFlowerCultivarsLazyQuery(variables: FlowerCultivarsQueryVariables | VueCompositionApi.Ref<FlowerCultivarsQueryVariables> | ReactiveFunction<FlowerCultivarsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FlowerCultivarsQuery, FlowerCultivarsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowerCultivarsQuery, FlowerCultivarsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowerCultivarsQuery, FlowerCultivarsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FlowerCultivarsQuery, FlowerCultivarsQueryVariables>(FlowerCultivarsDocument, variables, options);
}
export type FlowerCultivarsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FlowerCultivarsQuery, FlowerCultivarsQueryVariables>;
export const FlowerDayDocument = gql`
    query flowerDay($date: Date, $slug: String) {
  flowerDay(date: $date, slug: $slug) {
    date
    image_primary_override
    flower {
      uuid
      about_colors
      about_style
      about_symbolism
      advice_care_tips
      advice_meaning
      advice_presentation
      advice_sustainability
      advice_versatility
      availability
      care_nutrition
      care_sun
      care_cut
      care_prune
      care_vase
      care_water
      florists_count
      image_primary
      images_additional
      inspiration_intro
      intro
      origin
      origin_countries_count
      origin_top_countries
      seo {
        meta_description
        meta_follow
        meta_index
        meta_title
      }
      slug
      techniques_arrangement
      techniques_bouquets
      techniques_drying
      techniques_mono
      title
      title_latin
    }
    next {
      date
      flower {
        uuid
        slug
      }
    }
    previous {
      date
      flower {
        uuid
        slug
      }
    }
  }
}
    `;

/**
 * __useFlowerDayQuery__
 *
 * To run a query within a Vue component, call `useFlowerDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowerDayQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFlowerDayQuery({
 *   date: // value for 'date'
 *   slug: // value for 'slug'
 * });
 */
export function useFlowerDayQuery(variables: FlowerDayQueryVariables | VueCompositionApi.Ref<FlowerDayQueryVariables> | ReactiveFunction<FlowerDayQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<FlowerDayQuery, FlowerDayQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowerDayQuery, FlowerDayQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowerDayQuery, FlowerDayQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FlowerDayQuery, FlowerDayQueryVariables>(FlowerDayDocument, variables, options);
}
export function useFlowerDayLazyQuery(variables: FlowerDayQueryVariables | VueCompositionApi.Ref<FlowerDayQueryVariables> | ReactiveFunction<FlowerDayQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<FlowerDayQuery, FlowerDayQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowerDayQuery, FlowerDayQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowerDayQuery, FlowerDayQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FlowerDayQuery, FlowerDayQueryVariables>(FlowerDayDocument, variables, options);
}
export type FlowerDayQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FlowerDayQuery, FlowerDayQueryVariables>;
export const FlowerPicturesDocument = gql`
    query flowerPictures($flowerId: Uuid!, $limit: Int!, $offset: Int!) {
  flowerPictures(flower_id: $flowerId, limit: $limit, offset: $offset) {
    uuid
    flowers_primary {
      uuid
      title_latin
    }
    flowers {
      uuid
      slug
      title_latin
    }
    image
    seasons
    themes {
      uuid
      locales {
        locale
        slug
        title
      }
    }
  }
}
    `;

/**
 * __useFlowerPicturesQuery__
 *
 * To run a query within a Vue component, call `useFlowerPicturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowerPicturesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFlowerPicturesQuery({
 *   flowerId: // value for 'flowerId'
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 * });
 */
export function useFlowerPicturesQuery(variables: FlowerPicturesQueryVariables | VueCompositionApi.Ref<FlowerPicturesQueryVariables> | ReactiveFunction<FlowerPicturesQueryVariables>, options: VueApolloComposable.UseQueryOptions<FlowerPicturesQuery, FlowerPicturesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowerPicturesQuery, FlowerPicturesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowerPicturesQuery, FlowerPicturesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FlowerPicturesQuery, FlowerPicturesQueryVariables>(FlowerPicturesDocument, variables, options);
}
export function useFlowerPicturesLazyQuery(variables: FlowerPicturesQueryVariables | VueCompositionApi.Ref<FlowerPicturesQueryVariables> | ReactiveFunction<FlowerPicturesQueryVariables>, options: VueApolloComposable.UseQueryOptions<FlowerPicturesQuery, FlowerPicturesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowerPicturesQuery, FlowerPicturesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowerPicturesQuery, FlowerPicturesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FlowerPicturesQuery, FlowerPicturesQueryVariables>(FlowerPicturesDocument, variables, options);
}
export type FlowerPicturesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FlowerPicturesQuery, FlowerPicturesQueryVariables>;
export const FlowersDocument = gql`
    query flowers($dateEnd: Date!, $dateStart: Date!) {
  flowers(date_end: $dateEnd, date_start: $dateStart) {
    uuid
    date
    image_primary_override
    flower {
      uuid
      availability
      image_primary
      slug
      title
      title_latin
    }
  }
}
    `;

/**
 * __useFlowersQuery__
 *
 * To run a query within a Vue component, call `useFlowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFlowersQuery({
 *   dateEnd: // value for 'dateEnd'
 *   dateStart: // value for 'dateStart'
 * });
 */
export function useFlowersQuery(variables: FlowersQueryVariables | VueCompositionApi.Ref<FlowersQueryVariables> | ReactiveFunction<FlowersQueryVariables>, options: VueApolloComposable.UseQueryOptions<FlowersQuery, FlowersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowersQuery, FlowersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowersQuery, FlowersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FlowersQuery, FlowersQueryVariables>(FlowersDocument, variables, options);
}
export function useFlowersLazyQuery(variables: FlowersQueryVariables | VueCompositionApi.Ref<FlowersQueryVariables> | ReactiveFunction<FlowersQueryVariables>, options: VueApolloComposable.UseQueryOptions<FlowersQuery, FlowersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowersQuery, FlowersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowersQuery, FlowersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FlowersQuery, FlowersQueryVariables>(FlowersDocument, variables, options);
}
export type FlowersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FlowersQuery, FlowersQueryVariables>;
export const FlowersAlphabetDocument = gql`
    query flowersAlphabet($query: String) {
  flowersAlphabet(query: $query) {
    letter
    flowers {
      uuid
      availability
      image_primary
      slug
      title
      title_latin
    }
  }
}
    `;

/**
 * __useFlowersAlphabetQuery__
 *
 * To run a query within a Vue component, call `useFlowersAlphabetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowersAlphabetQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFlowersAlphabetQuery({
 *   query: // value for 'query'
 * });
 */
export function useFlowersAlphabetQuery(variables: FlowersAlphabetQueryVariables | VueCompositionApi.Ref<FlowersAlphabetQueryVariables> | ReactiveFunction<FlowersAlphabetQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<FlowersAlphabetQuery, FlowersAlphabetQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowersAlphabetQuery, FlowersAlphabetQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowersAlphabetQuery, FlowersAlphabetQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FlowersAlphabetQuery, FlowersAlphabetQueryVariables>(FlowersAlphabetDocument, variables, options);
}
export function useFlowersAlphabetLazyQuery(variables: FlowersAlphabetQueryVariables | VueCompositionApi.Ref<FlowersAlphabetQueryVariables> | ReactiveFunction<FlowersAlphabetQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<FlowersAlphabetQuery, FlowersAlphabetQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlowersAlphabetQuery, FlowersAlphabetQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlowersAlphabetQuery, FlowersAlphabetQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FlowersAlphabetQuery, FlowersAlphabetQueryVariables>(FlowersAlphabetDocument, variables, options);
}
export type FlowersAlphabetQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FlowersAlphabetQuery, FlowersAlphabetQueryVariables>;
export const PictureDocument = gql`
    query picture($id: Uuid!) {
  picture(id: $id) {
    uuid
    flowers {
      uuid
      slug
      title_latin
    }
    image
    seasons
    themes {
      uuid
      locales {
        locale
        slug
        title
      }
    }
  }
}
    `;

/**
 * __usePictureQuery__
 *
 * To run a query within a Vue component, call `usePictureQuery` and pass it any options that fit your needs.
 * When your component renders, `usePictureQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePictureQuery({
 *   id: // value for 'id'
 * });
 */
export function usePictureQuery(variables: PictureQueryVariables | VueCompositionApi.Ref<PictureQueryVariables> | ReactiveFunction<PictureQueryVariables>, options: VueApolloComposable.UseQueryOptions<PictureQuery, PictureQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PictureQuery, PictureQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PictureQuery, PictureQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PictureQuery, PictureQueryVariables>(PictureDocument, variables, options);
}
export function usePictureLazyQuery(variables: PictureQueryVariables | VueCompositionApi.Ref<PictureQueryVariables> | ReactiveFunction<PictureQueryVariables>, options: VueApolloComposable.UseQueryOptions<PictureQuery, PictureQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PictureQuery, PictureQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PictureQuery, PictureQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PictureQuery, PictureQueryVariables>(PictureDocument, variables, options);
}
export type PictureQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PictureQuery, PictureQueryVariables>;
export const PicturesDocument = gql`
    query pictures($flowerIds: [Uuid!], $limit: Int, $page: Int, $query: String, $seasons: [SeasonEnum!], $themeSlugs: [String!]) {
  pictures(
    flower_ids: $flowerIds
    limit: $limit
    page: $page
    query: $query
    seasons: $seasons
    theme_slugs: $themeSlugs
  ) {
    current_page
    data {
      uuid
      flowers {
        uuid
        slug
        title_latin
      }
      image
      seasons
      themes {
        uuid
        locales {
          locale
          slug
          title
        }
      }
    }
    has_more_pages
    per_page
    total
  }
}
    `;

/**
 * __usePicturesQuery__
 *
 * To run a query within a Vue component, call `usePicturesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePicturesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePicturesQuery({
 *   flowerIds: // value for 'flowerIds'
 *   limit: // value for 'limit'
 *   page: // value for 'page'
 *   query: // value for 'query'
 *   seasons: // value for 'seasons'
 *   themeSlugs: // value for 'themeSlugs'
 * });
 */
export function usePicturesQuery(variables: PicturesQueryVariables | VueCompositionApi.Ref<PicturesQueryVariables> | ReactiveFunction<PicturesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<PicturesQuery, PicturesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PicturesQuery, PicturesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PicturesQuery, PicturesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PicturesQuery, PicturesQueryVariables>(PicturesDocument, variables, options);
}
export function usePicturesLazyQuery(variables: PicturesQueryVariables | VueCompositionApi.Ref<PicturesQueryVariables> | ReactiveFunction<PicturesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<PicturesQuery, PicturesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PicturesQuery, PicturesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PicturesQuery, PicturesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PicturesQuery, PicturesQueryVariables>(PicturesDocument, variables, options);
}
export type PicturesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PicturesQuery, PicturesQueryVariables>;
export const SearchDocument = gql`
    query search($query: String!, $page: Int, $limit: Int) {
  search(query: $query, page: $page, limit: $limit) {
    __typename
    ... on Flower {
      uuid
      slug
      title_latin
    }
    ... on FlowerCultivar {
      uuid
      title
      flower {
        uuid
        slug
      }
    }
    ... on Story {
      uuid
      slug
      title_overview
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a Vue component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSearchQuery({
 *   query: // value for 'query'
 *   page: // value for 'page'
 *   limit: // value for 'limit'
 * });
 */
export function useSearchQuery(variables: SearchQueryVariables | VueCompositionApi.Ref<SearchQueryVariables> | ReactiveFunction<SearchQueryVariables>, options: VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, variables, options);
}
export function useSearchLazyQuery(variables: SearchQueryVariables | VueCompositionApi.Ref<SearchQueryVariables> | ReactiveFunction<SearchQueryVariables>, options: VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, variables, options);
}
export type SearchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SearchQuery, SearchQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    uuid
    address {
      city
      country_code: country
      house_number
      house_number_suffix
      latitude
      longitude
      street: title
      zipcode
    }
    email
    first_name
    last_name
    locale
    magazine_digital
    magazine_print
    newsletter
    specialty
    supplier {
      uuid
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const PageDocument = gql`
    query page($segments: String!) {
  page(segments: $segments) {
    active_image
    seo {
      meta_title
      meta_description
      meta_index
      meta_follow
    }
    slug
    template {
      __typename
      ... on TemplateStandaard {
        content
        video
      }
      ... on TemplateText {
        content
      }
    }
    title
    url
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a Vue component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageQuery({
 *   segments: // value for 'segments'
 * });
 */
export function usePageQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export function usePageLazyQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export type PageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageQuery, PageQueryVariables>;
export const StoriesDocument = gql`
    query stories($categorySlugs: [String!], $limit: Int, $page: Int, $query: String) {
  stories(
    category_slugs: $categorySlugs
    limit: $limit
    page: $page
    query: $query
  ) {
    current_page
    data {
      uuid
      active_image
      categories {
        id
        name
        slug
      }
      content_intro
      published_at
      image_flower_detail
      slug
      title
      title_overview
    }
    per_page
    total
  }
}
    `;

/**
 * __useStoriesQuery__
 *
 * To run a query within a Vue component, call `useStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStoriesQuery({
 *   categorySlugs: // value for 'categorySlugs'
 *   limit: // value for 'limit'
 *   page: // value for 'page'
 *   query: // value for 'query'
 * });
 */
export function useStoriesQuery(variables: StoriesQueryVariables | VueCompositionApi.Ref<StoriesQueryVariables> | ReactiveFunction<StoriesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<StoriesQuery, StoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StoriesQuery, StoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StoriesQuery, StoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<StoriesQuery, StoriesQueryVariables>(StoriesDocument, variables, options);
}
export function useStoriesLazyQuery(variables: StoriesQueryVariables | VueCompositionApi.Ref<StoriesQueryVariables> | ReactiveFunction<StoriesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<StoriesQuery, StoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StoriesQuery, StoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StoriesQuery, StoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<StoriesQuery, StoriesQueryVariables>(StoriesDocument, variables, options);
}
export type StoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<StoriesQuery, StoriesQueryVariables>;
export const StoryDocument = gql`
    query story($slug: String!) {
  story(slug: $slug) {
    active_image
    categories {
      id
      name
      slug
    }
    content_intro
    content_body
    published_at
    slug
    subject_name
    subject_quote
    subject_title
    title
  }
}
    `;

/**
 * __useStoryQuery__
 *
 * To run a query within a Vue component, call `useStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStoryQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useStoryQuery(variables: StoryQueryVariables | VueCompositionApi.Ref<StoryQueryVariables> | ReactiveFunction<StoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<StoryQuery, StoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StoryQuery, StoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StoryQuery, StoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<StoryQuery, StoryQueryVariables>(StoryDocument, variables, options);
}
export function useStoryLazyQuery(variables: StoryQueryVariables | VueCompositionApi.Ref<StoryQueryVariables> | ReactiveFunction<StoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<StoryQuery, StoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StoryQuery, StoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StoryQuery, StoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<StoryQuery, StoryQueryVariables>(StoryDocument, variables, options);
}
export type StoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<StoryQuery, StoryQueryVariables>;
export const SuppliersDocument = gql`
    query suppliers($latitude: Float, $limit: Int, $longitude: Float, $page: Int, $query: String, $type: SupplierTypeEnum) {
  suppliers(
    latitude: $latitude
    limit: $limit
    longitude: $longitude
    page: $page
    query: $query
    type: $type
  ) {
    current_page
    data {
      uuid
      title
    }
    per_page
    total
  }
}
    `;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a Vue component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSuppliersQuery({
 *   latitude: // value for 'latitude'
 *   limit: // value for 'limit'
 *   longitude: // value for 'longitude'
 *   page: // value for 'page'
 *   query: // value for 'query'
 *   type: // value for 'type'
 * });
 */
export function useSuppliersQuery(variables: SuppliersQueryVariables | VueCompositionApi.Ref<SuppliersQueryVariables> | ReactiveFunction<SuppliersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SuppliersQuery, SuppliersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SuppliersQuery, SuppliersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SuppliersQuery, SuppliersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, variables, options);
}
export function useSuppliersLazyQuery(variables: SuppliersQueryVariables | VueCompositionApi.Ref<SuppliersQueryVariables> | ReactiveFunction<SuppliersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SuppliersQuery, SuppliersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SuppliersQuery, SuppliersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SuppliersQuery, SuppliersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, variables, options);
}
export type SuppliersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SuppliersQuery, SuppliersQueryVariables>;
export const ThemesDocument = gql`
    query themes {
  themes {
    uuid
    slug
    title
  }
}
    `;

/**
 * __useThemesQuery__
 *
 * To run a query within a Vue component, call `useThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useThemesQuery();
 */
export function useThemesQuery(options: VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, {}, options);
}
export function useThemesLazyQuery(options: VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, {}, options);
}
export type ThemesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ThemesQuery, ThemesQueryVariables>;
export const TrendingThemesDocument = gql`
    query trendingThemes {
  trendingThemes {
    uuid
    locales {
      locale
      slug
      title
    }
  }
}
    `;

/**
 * __useTrendingThemesQuery__
 *
 * To run a query within a Vue component, call `useTrendingThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingThemesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTrendingThemesQuery();
 */
export function useTrendingThemesQuery(options: VueApolloComposable.UseQueryOptions<TrendingThemesQuery, TrendingThemesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrendingThemesQuery, TrendingThemesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrendingThemesQuery, TrendingThemesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TrendingThemesQuery, TrendingThemesQueryVariables>(TrendingThemesDocument, {}, options);
}
export function useTrendingThemesLazyQuery(options: VueApolloComposable.UseQueryOptions<TrendingThemesQuery, TrendingThemesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrendingThemesQuery, TrendingThemesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrendingThemesQuery, TrendingThemesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TrendingThemesQuery, TrendingThemesQueryVariables>(TrendingThemesDocument, {}, options);
}
export type TrendingThemesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TrendingThemesQuery, TrendingThemesQueryVariables>;
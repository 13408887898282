import validate from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_next-auth@4.21.1_rollup@4.19.2/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}
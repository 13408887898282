
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "fr",
  "nl",
  "pl"
]

export const localeLoaders = {
  "de": [{ key: "../translations/de-DE.json", load: () => import("../translations/de-DE.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_translations_de_DE_json" */), cache: true }],
  "en": [{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_0_2_nuxt_643_12_4_rollup_644_19_2_tailwindcss_643_4_7_vite_645_3_5_vue_643_4_35_node_modules__64bttr_devs_nuxt_layer_base_locales_en_json" */), cache: true },
{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_3_3_nuxt_643_12_4_react_dom_6418_3_1_react_6418_3_1_rollup_644_19_2_tai_ei7ycvutainacvdkeaw6pqp73m_node_modules__64bttr_devs_nuxt_layer_sofie_locales_en_json" */), cache: true },
{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_auth_641_3_2_next_6413_5_6_nuxt_643_12_4_react_dom_6418_3_1_react_6418_3_1__akykcum6ksbejjzk43zjaxiw6y_node_modules__64bttr_devs_nuxt_layer_sofie_auth_locales_en_json" */), cache: true },
{ key: "../translations/en-US.json", load: () => import("../translations/en-US.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_translations_en_US_json" */), cache: true }],
  "fr": [{ key: "../translations/fr-FR.json", load: () => import("../translations/fr-FR.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_translations_fr_FR_json" */), cache: true }],
  "nl": [{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_0_2_nuxt_643_12_4_rollup_644_19_2_tailwindcss_643_4_7_vite_645_3_5_vue_643_4_35_node_modules__64bttr_devs_nuxt_layer_base_locales_nl_json" */), cache: true },
{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_3_3_nuxt_643_12_4_react_dom_6418_3_1_react_6418_3_1_rollup_644_19_2_tai_ei7ycvutainacvdkeaw6pqp73m_node_modules__64bttr_devs_nuxt_layer_sofie_locales_nl_json" */), cache: true },
{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_auth_641_3_2_next_6413_5_6_nuxt_643_12_4_react_dom_6418_3_1_react_6418_3_1__akykcum6ksbejjzk43zjaxiw6y_node_modules__64bttr_devs_nuxt_layer_sofie_auth_locales_nl_json" */), cache: true },
{ key: "../translations/nl-NL.json", load: () => import("../translations/nl-NL.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_translations_nl_NL_json" */), cache: true }],
  "pl": [{ key: "../translations/pl-PL.json", load: () => import("../translations/pl-PL.json" /* webpackChunkName: "locale__data_www_365dagenbloemen_nl_365dagenbloemen_nuxt_translations_pl_PL_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "domainDefault": true,
      "domain": "365tageblumen.de",
      "code": "de",
      "files": [
        "translations/de-DE.json"
      ]
    },
    {
      "code": "en",
      "domainDefault": true,
      "domain": "365daysofflowers.com",
      "files": [
        "node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json",
        "node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json",
        "node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json",
        "translations/en-US.json"
      ]
    },
    {
      "domainDefault": true,
      "domain": "365joursdefleurs.fr",
      "code": "fr",
      "files": [
        "translations/fr-FR.json"
      ]
    },
    {
      "code": "nl",
      "domainDefault": true,
      "domain": "365dagenbloemen.nl",
      "files": [
        "node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json",
        "node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json",
        "node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json",
        "translations/nl-NL.json"
      ]
    },
    {
      "domainDefault": true,
      "domain": "365dnikwiatow.pl",
      "code": "pl",
      "files": [
        "translations/pl-PL.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "translations",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "availability": {
      "de": "/verfugbarkeit",
      "fr": "/disponibilite",
      "nl": "/beschikbaarheid",
      "fi": "/saatavuus",
      "da": "/tilgaengelighed",
      "sv": "/tillganglighet",
      "it": "/disponibilita",
      "es": "/disponibilidad",
      "pl": "/dostepnosc"
    },
    "flowers/index": {
      "de": "/blumen",
      "fr": "/fleurs",
      "nl": "/bloemen",
      "fi": "/kukkia",
      "da": "/blomster",
      "sv": "/blommor",
      "it": "/fiori",
      "es": "/flores",
      "pl": "/kwiaty"
    },
    "flowers/[slug]": {
      "de": "/blumen/[slug]",
      "fr": "/fleurs/[slug]",
      "nl": "/bloemen/[slug]",
      "fi": "/kukkia/[slug]",
      "da": "/blomster/[slug]",
      "sv": "/blommor/[slug]",
      "it": "/fiori/[slug]",
      "es": "/flores/[slug]",
      "pl": "/kwiaty/[slug]"
    },
    "inspiration": {
      "de": "/inspiration",
      "fr": "/inspiration",
      "nl": "/inspiratie",
      "fi": "/inspiraatio",
      "da": "/inspiration",
      "sv": "/inspiration",
      "it": "/ispirazione",
      "es": "/inspiracion",
      "pl": "/inspiracja"
    },
    "stories/index": {
      "de": "/geschichten",
      "fr": "/histoires",
      "nl": "/verhalen",
      "fi": "/tarinat",
      "da": "/historier",
      "sv": "/berattelser",
      "it": "/storie",
      "es": "/historias",
      "pl": "/historie"
    },
    "schedule/index": {
      "de": "/tagesordnung",
      "fr": "/ordre-du-jour",
      "nl": "/agenda",
      "fi": "/ajoittaa",
      "da": "/tidsplan",
      "sv": "/schema",
      "it": "/programma",
      "es": "/cronograma",
      "pl": "/kalendarz"
    },
    "stories/[slug]": {
      "de": "/geschichten/[slug]",
      "fr": "/histoires/[slug]",
      "nl": "/verhalen/[slug]",
      "fi": "/tarinat/[slug]",
      "da": "/historier/[slug]",
      "sv": "/berattelser/[slug]",
      "it": "/storie/[slug]",
      "es": "/historias/[slug]",
      "pl": "/historie/[slug]"
    },
    "subscribe": {
      "de": "/abonnieren-sie",
      "fr": "/abonnez-vous",
      "nl": "/word-abonnee",
      "fi": "/tilata",
      "da": "/abonnere",
      "sv": "/prenumerera",
      "it": "/sottoscrivi",
      "es": "/suscribir",
      "pl": "/subskrybuj"
    },
    "campaign-request": {
      "de": "/kampagnen-anfrage",
      "fr": "/demande-de-campagne",
      "nl": "/campagne-aanvraag",
      "fi": "/kampanjapyynto",
      "da": "/kampagne-anmodning",
      "sv": "/kampanj-forfragan",
      "it": "/campagna-richiesta",
      "es": "/campana-solicitud",
      "pl": "/kampania-zapytanie"
    },
    "disclaimer-and-terms-of-use": {
      "de": "/haftungsausschluss-und-nutzungsbedingungen",
      "fr": "/disclaimer",
      "nl": "/disclaimer-en-gebruiksvoorwaarden",
      "fi": "/vastuuvapauslauseke-ja-kayttoehdot",
      "da": "/ansvarsfraskrivelse-og-vilkar-for-brug",
      "sv": "/ansvarsfriskrivning-och-anvandarvillkor",
      "it": "/esclusione-di-responsabilita-e-condizioni-di-utilizzo",
      "es": "/aviso-legal-y-condiciones-de-uso",
      "pl": "/wylaczenie-odpowiedzialnosci-i-warunki-uzytkowania"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "domainDefault": true,
    "domain": "365tageblumen.de",
    "code": "de",
    "files": [
      {
        "path": "translations/de-DE.json"
      }
    ]
  },
  {
    "code": "en",
    "domainDefault": true,
    "domain": "365daysofflowers.com",
    "files": [
      {
        "path": "node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json"
      },
      {
        "path": "node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json"
      },
      {
        "path": "node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json"
      },
      {
        "path": "translations/en-US.json"
      }
    ]
  },
  {
    "domainDefault": true,
    "domain": "365joursdefleurs.fr",
    "code": "fr",
    "files": [
      {
        "path": "translations/fr-FR.json"
      }
    ]
  },
  {
    "code": "nl",
    "domainDefault": true,
    "domain": "365dagenbloemen.nl",
    "files": [
      {
        "path": "node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json"
      },
      {
        "path": "node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json"
      },
      {
        "path": "node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json"
      },
      {
        "path": "translations/nl-NL.json"
      }
    ]
  },
  {
    "domainDefault": true,
    "domain": "365dnikwiatow.pl",
    "code": "pl",
    "files": [
      {
        "path": "translations/pl-PL.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

<template>
  <component
    :is="to ? NuxtLink : 'button'"
    class="flex items-center justify-center font-medium no-underline transition-all disabled:cursor-not-allowed disabled:opacity-30 disabled:saturate-0 print:hidden"
    :class="[
      type === 'submit' ? colorVariants['primary'] : colorVariants[type],
      label ? (size === 'small' ? 'w-fit p-2' : 'w-fit px-5 py-3') : 'flex h-14 w-14 items-center justify-center',
      shape === 'pill' ? 'rounded-full' : 'rounded-lg',
      size === 'small' ? 'gap-2 text-base' : 'gap-4 text-lg',
      disabled ? 'opacity-30 saturate-0' : ''
    ]"
    :disabled="disabled || loading ? 'disabled' : undefined"
    :to="to ? localePath(to) : undefined"
    :type="type === 'submit' ? 'submit' : 'button'"
  >
    <UiIcon
      v-if="iconPrefix"
      :name="iconPrefix"
      :size="iconPrefixSize"
    />
    {{ label }}
    <UiIcon
      v-if="icon"
      :name="icon"
      :size="iconSize"
    />
    <UiIcon
      v-if="loading"
      :size="iconSize"
      name="svg-spinners:ring-resize"
    />
  </component>
</template>

<script lang="ts" setup>
import {NuxtLink} from '#components';
import type {RouteLocationRaw} from 'vue-router';

const colorVariants = {
  primary: 'bg-primary hover:bg-primary-800 text-white',
  primaryOutline: 'border-primary text-primary hover:bg-primary-500 border',
  secondary: 'bg-grey hover:bg-grey-800 text-white',
  secondaryOutline: 'border-grey text-grey hover:bg-grey-500 border',
  tertiary: 'text-grey hover:bg-primary-400 hover:text-primary flex items-center justify-center rounded-full bg-white',
};

withDefaults(defineProps<{
  disabled?: boolean
  icon?: string
  iconPrefix?: string
  iconPrefixSize?: 'tiny' | 'small' | 'medium' | 'large'
  iconSize?: 'tiny' | 'small' | 'medium' | 'large'
  label?: string
  loading?: boolean
  shape?: 'pill',
  size?: 'small' | undefined
  to?: RouteLocationRaw,
  type?: 'primary' | 'primaryOutline' | 'secondary' | 'secondaryOutline' | 'tertiary' | 'submit' | 'icon'
}>(), {
  disabled: false,
  icon: undefined,
  iconPrefix: undefined,
  iconPrefixSize: 'small',
  iconSize: 'small',
  label: undefined,
  loading: false,
  shape: undefined,
  size: undefined,
  to: undefined,
  type: 'primary',
});
</script>

import revive_payload_client_AD6rHGDZqG from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_J5uIhckjrY from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kx8eeOy3qL from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0GPOedQvPc from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.19.2_vite@5.3.5_vue@3.4.35/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_LR60Pi8uop from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_brSy8u7llt from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_j0bbYptJDX from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zREnYlcVmG from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_G63r3BIcFf from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_stylelint@16.8.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fSuRE4FZlf from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.19.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_QWH6nb054Y from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.19.2_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_XGQP2oSlra from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.19.2_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_CV0q1KE7tl from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_typescript@5.5.4_vue@3.4.35/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_rDM2eMaqnr from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_next-auth@4.21.1_rollup@4.19.2/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import errors_tOLqJB0MHq from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_ktNfZiqkeC from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_nuxt@3.12.4_rollup@4.19.2_tailwindcss@3.4.7_vite@5.3.5_vue@3.4.35/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_G97L0fu5Zi from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_ASKAOjjF5f from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1_rollup@4.19.2_tai_ei7ycvutainacvdkeaw6pqp73m/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_iXiolW4kdG from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_AVw8ehE0SZ from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_next@13.5.6_nuxt@3.12.4_react-dom@18.3.1_react@18.3.1__akykcum6ksbejjzk43zjaxiw6y/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import apollo_1ILFkzpYSr from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/apollo.ts";
import dynamic_vw_client_kS7b5RjaKG from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/dynamic-vw.client.ts";
import gtm_client_OzmBuHLRIb from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/gtm.client.ts";
import i18n_VfGcjrvSkj from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/i18n.ts";
import notifications_Wskzy5ZO71 from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/notifications.ts";
import session_qoMCkkKHp8 from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/session.ts";
import version_client_0D8DwfTb7v from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/version.client.ts";
export default [
  revive_payload_client_AD6rHGDZqG,
  unhead_J5uIhckjrY,
  router_kx8eeOy3qL,
  _0_siteConfig_0GPOedQvPc,
  payload_client_LR60Pi8uop,
  navigation_repaint_client_brSy8u7llt,
  check_outdated_build_client_j0bbYptJDX,
  chunk_reload_client_zREnYlcVmG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_G63r3BIcFf,
  plugin_fSuRE4FZlf,
  switch_locale_path_ssr_QWH6nb054Y,
  i18n_XGQP2oSlra,
  formkitPlugin_pZqjah0RUG,
  plugin_CV0q1KE7tl,
  plugin_rDM2eMaqnr,
  errors_tOLqJB0MHq,
  i18n_ktNfZiqkeC,
  apollo_G97L0fu5Zi,
  sofie_required_version_ASKAOjjF5f,
  apollo_iXiolW4kdG,
  auth_AVw8ehE0SZ,
  apollo_1ILFkzpYSr,
  dynamic_vw_client_kS7b5RjaKG,
  gtm_client_OzmBuHLRIb,
  i18n_VfGcjrvSkj,
  notifications_Wskzy5ZO71,
  session_qoMCkkKHp8,
  version_client_0D8DwfTb7v
]
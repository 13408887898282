import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAngleUp, LazyArrowLeft, LazyArrowRight, LazyArrowRightFromBracket, LazyBagSeedling, LazyBasketShopping, LazyBrandIcon, LazyCheck, LazyChevronLeft, LazyCircleInfo, LazyCultivarColor, LazyEight, LazyFive, LazyFour, LazyGear, LazyHouseChimney, LazyLeaf, LazyNine, LazyOne, LazyPencil, LazyPrint, LazyQr, LazyRaindrops, LazyRulerTriangle, LazySeven, LazySix, LazySun, LazyThree, LazyTwo, LazyVase, LazyXMark, LazyZero, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AngleUp", LazyAngleUp],
["ArrowLeft", LazyArrowLeft],
["ArrowRight", LazyArrowRight],
["ArrowRightFromBracket", LazyArrowRightFromBracket],
["BagSeedling", LazyBagSeedling],
["BasketShopping", LazyBasketShopping],
["BrandIcon", LazyBrandIcon],
["Check", LazyCheck],
["ChevronLeft", LazyChevronLeft],
["CircleInfo", LazyCircleInfo],
["CultivarColor", LazyCultivarColor],
["Eight", LazyEight],
["Five", LazyFive],
["Four", LazyFour],
["Gear", LazyGear],
["HouseChimney", LazyHouseChimney],
["Leaf", LazyLeaf],
["Nine", LazyNine],
["One", LazyOne],
["Pencil", LazyPencil],
["Print", LazyPrint],
["Qr", LazyQr],
["Raindrops", LazyRaindrops],
["RulerTriangle", LazyRulerTriangle],
["Seven", LazySeven],
["Six", LazySix],
["Sun", LazySun],
["Three", LazyThree],
["Two", LazyTwo],
["Vase", LazyVase],
["XMark", LazyXMark],
["Zero", LazyZero],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
